import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'

import { fetchSuppliers, fetchProducts } from '../../util/functions/fetch/'
import { removeKeys, removeMatchingKeys } from '../../util/functions/fetch/removeKey'

import { useFilters } from './useFilters'

import { Query } from '../../models/Query'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const PRODUCT_SORT_OPTIONS = [
  {
    title: 'Name (A-Z)',
    param: 'sortAlphabetically',
  },
  {
    title: 'Price (Lowest)',
    param: 'sortLowestPrice',
  },
  {
    title: 'Price (Highest)',
    param: 'sortHighestPrice',
  },
  {
    title: 'Newest',
    param: 'sortNewest',
  },
  {
    title: 'Oldest',
    param: 'sortOldest',
  },
]

const COMPANIES_SORT_OPTIONS = [
  {
    title: 'Name (A-Z)',
    param: 'sortAlphabetically',
  },
  {
    title: 'Newest',
    param: 'sortNewest',
  },
  {
    title: 'Oldest',
    param: 'sortOldest',
  },
]

export const useCatalogue = () => {
  const { filters, loading: isFilterLoading } = useFilters()
  const location = useLocation()

  const [title, setTitle] = useState('Products')
  const [view, setView] = useState('grid')
  const [currentTab, setCurrentTab] = useState('products')

  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [paginationSize, setPaginationSize] = useState(12)

  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])
  const [params, setParams] = useState({})

  const getData = useCallback(
    async (params) => {
      setLoading(true)
      try {
        let { data, pagination } =
          currentTab === 'products'
            ? await fetchProducts(params)
            : await fetchSuppliers(params)
        setData(data)
        setLoading(false)
        setPagination(pagination)
      } catch (error) {
        if (!TypeError === error) {
          message.error('Something went wrong, please try again later.')
          console.error(error)
        }
      }
    },
    [currentTab],
  )

  const handleSelectChange = useCallback(
    ({ query, value }) => {
      let parsedParams = { ...params }
      const regex = new RegExp(`^${query}.*?(?=&|$)`)
      const queryBuilder = new Query(null, null, ',')
      const currentValues = parsedParams[query]?.split(',')
      let values = currentValues ? [...currentValues] : []

      values = values.filter((val) => val !== '')
      if (values.includes(value)) {
        values = values.filter((val) => val !== value)
      } else {
        values.push(value)
      }

      parsedParams = removeMatchingKeys(parsedParams, regex)

      values.forEach((value) => {
        queryBuilder.append(value)
      })

      setCurrentPage(1)
      setParams({ ...parsedParams, [query]: queryBuilder.buildParams() })
    },
    [params],
  )

  const handlePriceRangeChange = useCallback(
    ({ min, max }) => {
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^price.*?(?=&|$)/)
      setParams({ ...parsedParams, priceRange: `${min},${max}` })
    },
    [params],
  )

  const handleCategoryChange = useCallback(
    (categories = [], name) => {
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^category.*?(?=&|$)/)
      parsedParams = removeKeys(parsedParams, ['brand', 'title', 'supplier'])

      const query = new Query(null, null, ',')
      categories.forEach((category) => {
        query.append(category)
      })

      setLoading(true)
      setTitle(name)
      setCurrentPage(1)
      setParams({ ...parsedParams, category: query.buildParams() })
    },
    [params],
  )

  const handleSortChange = useCallback(
    (sort) => {
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^sort.*?(?=&|$)/)
      setParams({ ...parsedParams, ...sort })
    },
    [params],
  )

  const handlePaginationChange = useCallback((page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }, [])

  const handleTabChange = useCallback(
    (key) => {
      if (params['supplier']) {
        let parsedParams = { ...params }
        parsedParams = removeMatchingKeys(parsedParams, /^supplier.*?(?=&|$)/)
        parsedParams = removeKeys(parsedParams, ['brand', 'title', 'category'])
        setParams(parsedParams)
      }

      setCurrentTab(key)
      setCurrentPage(1)
      setView('grid')
      setData([])
    },
    [params],
  )

  const handleSupplierChange = useCallback(
    (suppliers = []) => {
      handleTabChange('products')
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^supplier.*?(?=&|$)/)
      parsedParams = removeKeys(parsedParams, ['brand', 'title', 'category'])

      const query = new Query(null, null, ',')
      suppliers.forEach((supplier) => {
        query.append(supplier.id)
      })

      setLoading(true)
      setParams({
        ...parsedParams,
        supplier: query.buildParams(),
        title: `${suppliers[0].name}'s Products`,
      })
    },
    [params, handleTabChange],
  )

  const handleClearFilters = () => {
    setParams({})
    setCurrentPage(1)
  }

  useEffect(() => {
    if (!params.category && !params.brand) {
      if (currentTab === 'products') {
        setTitle('Products')
      } else {
        setTitle('Sellers')
      }
    }
    if (params.title) {
      setTitle(params.title)
    }
  }, [currentTab, params])

  useEffect(() => {
    if (window.innerWidth < 576) {
      setPaginationSize(8)
    } else if (window.innerWidth >= 576 && window.innerWidth < 992) {
      setPaginationSize(8)
    } else if (window.innerWidth >= 992 && window.innerWidth < 1900) {
      setPaginationSize(12)
    } else {
      setPaginationSize(15)
    }
  }, [])

  useEffect(() => {
    getData({ ...params, page: currentPage, perPage: paginationSize })
  }, [params, paginationSize, currentPage, getData])

  useEffect(() => {
    let search = location
    const params = new URLSearchParams(search.search)
    let currentParams = { ...params }

    if (params.has('category')) {
      const category = params.get('category')
      currentParams = { ...currentParams, category }
    }

    if (params.has('brand')) {
      const brand = params.get('brand')
      currentParams = { ...currentParams, brand }
    }

    if (params.has('country')) {
      const country = params.get('country')
      currentParams = { ...currentParams, country }
    }

    if (params.has('supplier')) {
      const supplier = params.get('supplier')
      currentParams = { ...currentParams, supplier }
    }

    if (params.has('title')) {
      const title = params.get('title')
      currentParams = { ...currentParams, title }
    }

    setParams({ ...currentParams, sortNewest: true })
  }, [])

  return {
    title,
    view,
    setView,
    currentTab,
    currentPage,
    setCurrentPage,
    pagination,
    loading,
    data,
    handleTabChange,
    handlePaginationChange,
    handleSortChange,
    handleCategoryChange,
    handlePriceRangeChange,
    handleSelectChange,
    filters,
    isFilterLoading,
    handleSupplierChange,
    handleClearFilters,
    sortOptions:
      currentTab === 'products' ? PRODUCT_SORT_OPTIONS : COMPANIES_SORT_OPTIONS,
  }
}
