import { Skeleton, Pagination } from 'antd'

import { useCatalogue } from '../../../hooks/ui/useCatalogue'

import Filters from '../../../components/pages/marketplace/explore/Filters'
import Header from '../../../components/pages/marketplace/explore/Header'

import { CardList } from '../../../components/ui/cards/CardList/CardList'
import { CompanyCard } from '../../../components/ui/cards'
import { ProductCard } from '../../../components/ui/cards/ProductCard/ProductCard'
import { redirectProductDetails } from '../../../util/functions'

// TODO: Create module for tabs data
const TABS = [
  {
    key: 'products',
    title: 'Products',
  },
  {
    key: 'sellers',
    title: 'Sellers',
  },
]

// This page is going to be redevelop to include:
// TODO: Search system
// TODO: Implement search system
// TODO: Create storybook
// TODO: Create tests
const Catalogue = () => {
  const {
    title,
    currentTab,
    handleTabChange,
    handlePaginationChange,
    handleCategoryChange,
    sortOptions,
    handleSortChange,
    loading,
    pagination,
    data,
    view,
    setView,
    handlePriceRangeChange,
    handleSelectChange,
    handleSupplierChange,
    handleClearFilters,
    filters,
    isFilterLoading,
  } = useCatalogue()

  const isHideViewToggle = () => {
    return currentTab === 'products' && window.innerWidth > 1024
  }

  return (
    <div className="container mb-5">
      <div className="products-list">
        <div className="filters">
          <Filters
            filters={filters}
            isLoading={isFilterLoading}
            onCategoryChange={handleCategoryChange}
            onPriceRangeChange={handlePriceRangeChange}
            onSelectchange={handleSelectChange}
          />
        </div>
        <div className="products">
          <Header
            title={title}
            view={view}
            setView={setView}
            tabs={TABS}
            onTabChange={handleTabChange}
            onViewChange={setView}
            onSortChange={handleSortChange}
            sortOptions={sortOptions}
            currentTab={currentTab}
            hideViewToggle={isHideViewToggle()} // FIXME: isHideViewToggle is not being used
            filters={filters}
            isLoadingFilters={isFilterLoading}
            onCategoryChange={handleCategoryChange}
            onPriceRangeChange={handlePriceRangeChange}
            onSelectchange={handleSelectChange}
            onClearFilters={handleClearFilters}
          />
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <CardList
                view={view}
                className={currentTab !== 'products' && 'card-list--companies'}
              >
                {currentTab === 'products' ? (
                  <>
                    {data &&
                      data.map((item, index) => (
                        <ProductCard
                          className={'product-card--catalogue'}
                          key={index}
                          product={item}
                          disableProductLinks={false}
                          isSellerShowroom={false}
                          shopOptions={false}
                          view={view}
                          onClick={() => redirectProductDetails(item.slug)}
                        />
                      ))}
                  </>
                ) : (
                  <>
                    {data &&
                      data.map(
                        (item, index) =>
                          item.get_images && (
                            <CompanyCard
                              company={item}
                              key={index}
                              view={view}
                              onViewProducts={handleSupplierChange}
                              onImageClick={handleSupplierChange}
                            />
                          ),
                      )}
                  </>
                )}
                {/* <CompanyCard /> */}
              </CardList>
              <div className="d-flex justify-content-center mt-4">
                {pagination && pagination.per_page && pagination.current_page && (
                  <Pagination
                    size="small"
                    current={pagination.current_page}
                    pageSize={pagination.per_page}
                    total={pagination.total}
                    showSizeChanger={false}
                    onChange={handlePaginationChange}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Catalogue
